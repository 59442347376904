@media screen and (max-width: 800px) {
    .placeholder {
        margin-top: 0px;
    }
}

@media screen and (max-width: 1300px) and (min-width: 800px) {
    .placeholder{
        margin-top: -5.5rem;
    }
}
